import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { isClient } from "utils/utils";
import { useRouter } from "next/router";
import styles from "../styles/Home.module.scss";

export default function Custom404() {
  const router = useRouter();
  const [message, setMessage] = useState(
    router.asPath.split("message=")[1]?.replaceAll("_", " ") ?? ""
  );

  useEffect(() => {
    Cookies.remove("token");
    Cookies.remove("cart");
    isClient && localStorage.clear;
  }, []);

  useEffect(() => {
    // Wait for the router to be ready before accessing the query
    if (router.isReady) {
      setMessage(router.query.message as string);
    } else {
      setMessage(router.asPath.split("message=")[1]);
    }
  }, [router.isReady, router.query, router.asPath]);

  return (
    <div className={styles.notFound}>
      {router.query.message}
      <h1>{message?.replaceAll("_", " ") ?? "Please refresh"}</h1>
    </div>
  );
}
